import * as React from "react"
import { Helmet } from "react-helmet"
import Layout_interne from "../layouts/Layout_interne"
import { useStaticQuery, graphql } from "gatsby"
import {Link} from 'gatsby'
const Contatti = () => {

  const data = useStaticQuery(graphql`
    {    
      contatti: file(relativePath: { eq: "contatti.svg" }) {
                      relativePath
                      publicURL
                  }   
    }
  `)

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contatti</title>
        <meta name="description" content="Vuoi capire meglio quali vantaggi avrebbe il tuo sito passando alle tecnologie jamstack?" />
      </Helmet>
      <Layout_interne>
        <section className="maincontainer risorse-lista ">
        <img width="460" height="436" class="image-risorse" src={data.contatti.publicURL} />
          <h1>Contattaci</h1>
          <p>
            <ul className="lista_contatti">
              <li>
                Vuoi capire meglio quali vantaggi avrebbe il tuo sito passando alle tecnologie jamstack?
      </li>
              <li>
                Vuoi avere una consulenza personalizzata, valutare lo stato delle tue risorse online alla luce delle nuove possibilità?
      </li>
              <li>
                Vuoi proporci qualcosa di nuovo e interessante, una risorsa, una tecnologia...?
      </li>
              <li>
                Vuoi conoscerci meglio e magari prendere un caffè?
      </li>
            </ul>
          </p>


          <div className="form-contatti">

          <form method="POST" name="contatti" action="/thanks" netlify-honeypot="bot-field" data-netlify="true">
          <input type="hidden" name="form-name" value="contatti" />
              <label htmlFor="nome">
                <input placeholder="nome" type="text" name="nome" id="nome" required/>
              </label>
              <label htmlFor="url">
                <input placeholder="Il tuo sito personale o aziendale" type="url" name="url" id="sito" />
              </label>
              <label htmlFor="email">
                <input placeholder="email" type="text" name="email" id="email" required/>
              </label>
              <label htmlFor="messaggio">
                <textarea placeholder="messaggio" name="messaggio" id="messaggio" required/>
              </label>
              <label htmlFor="privacy">
                  <input type="checkbox" placeholder="privacy" name="privacy" id="privacy" required/>
                  <span>Accettazione della <Link to="/privacy"> Privacy</Link></span> 
              </label>
              <label htmlFor="submit">
                <input type="submit" value="invia" name="submit" />
              </label>
            </form>
          </div>

        </section>
      </Layout_interne>
    </>
  )
}

export default Contatti
